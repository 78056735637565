import React from 'react'
import { Link }  from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'

const AboutMe = () => {
    return(
        <Layout>
        <Head title='About'/>
            <h1>About Me</h1>
            <h2>this will be updated</h2>
            <p><Link to="/contact">Contact Us</Link></p>
        </Layout>
    )
}

export default AboutMe